import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import Switch from './Switch/Switch';
import emailjs from '@emailjs/browser';

function App() {

  const [cleanSDB, setCleanSDB] = useState(false);
  const [windowSDB, setWindowSDB] = useState(false);
  const [vmcSDB, setVMCSDB] = useState(false);
  const [warmNap, setWarmNap] = useState(false);
  const [equipedKitchen, setEquipedKitchen] = useState(false);
  const [recentEquipement, setRecentEquipement] = useState(false);
  const [washMachine, setWashMachine] = useState(false);
  const [whereWashMachine, setWhereWashMachine] = useState(false);
  const [oven, setOven] = useState(false);
  const [microwave, setMicroWave] = useState(false);
  const [dishWasher, setDishWasher] = useState(false);
  const [whereDishWasher, setWhereDishWasher] = useState(false);
  const [bed, setBed] = useState(false);
  const [stateBed, setStateBed] = useState(false);
  const [stateAppartment, setStateAppartment] = useState(false);
  const [vitrage, setVitrage] = useState(false);
  const [recentRadiator, setRecentRadiator] = useState(false);
  const [light, setLight] = useState(false);
  const [visavis, setVisavis] = useState(false);
  const [noise, setNoise] = useState(false);
  const [pc, setPC] = useState(false);
  const [road, setRoad] = useState(false);

  const send = (e) => {
    e.preventDefault();
    console.log(e);

    emailjs.sendForm('service_ggfskeu', 'template_wx2jt1k', e.target, '2TOlf0fjX1FzArERo')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
    <main className='flex flex-col w-[90%] mx-auto justify-center'>
      <h1 className='text-center mt-[50px] text-[30px] font-bold'>Formulaire de visite</h1>
      <form className='flex flex-col mt-[25px]' onSubmit={send}>
        <input className='hidden' name='cleanSDB' value={cleanSDB ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Salle d'eau en bonne état (pas de champignon, peinture ne s'écaille pas, état des joints) ?</label>
          <Switch checked={cleanSDB} onSelect={(checked)=> setCleanSDB(checked)}/> 
        </div>
        <input className='hidden' name='windowSDB' value={windowSDB ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Fenêtre dans la salle d'eau ?</label>
          <Switch checked={windowSDB} onSelect={(checked)=> setWindowSDB(checked)}/> 
        </div>
        <input className='hidden' name='vmcSDB' value={vmcSDB ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>VMC dans la salle d'eau ?</label>
          <Switch checked={vmcSDB} onSelect={(checked)=> setVMCSDB(checked)}/> 
        </div>
        <input className='hidden' name='warmNap' value={warmNap ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Y'a t'il un chauffe serviette ?</label>
          <Switch checked={warmNap} onSelect={(checked)=> setWarmNap(checked)}/> 
        </div>
        <input className='hidden' name='equipedKitchen' value={equipedKitchen ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Cuisine équipée ?</label>
          <Switch checked={equipedKitchen} onSelect={(checked)=> setEquipedKitchen(checked)}/> 
        </div>
        <input className='hidden' name='recentEquipement' value={recentEquipement ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Equipement récent ou non ?</label>
          <Switch checked={recentEquipement} onSelect={(checked)=> setRecentEquipement(checked)}/> 
        </div>
        <input className='hidden' name='washMachine' value={washMachine ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Y'a t'il une machine à laver le linge ?</label>
          <Switch checked={washMachine} onSelect={(checked)=> setWashMachine(checked)}/> 
        </div>
        <input className='hidden' name='whereWashMachine' value={whereWashMachine ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Y'a t'il un emplacement pour brancher une machine à laver ?</label>
          <Switch checked={whereWashMachine} onSelect={(checked)=> setWhereWashMachine(checked)}/> 
        </div>
        <input className='hidden' name='oven' value={oven ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Y'a t'il un four ?</label>
          <Switch checked={oven} onSelect={(checked)=> setOven(checked)}/> 
        </div>
        <input className='hidden' name='microwave' value={microwave ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Y'a t'il un micro-onde ?</label>
          <Switch checked={microwave} onSelect={(checked)=> setMicroWave(checked)}/> 
        </div>
        <input className='hidden' name='dishWasher' value={dishWasher ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Y'a t'il un lave vaisselle ?</label>
          <Switch checked={dishWasher} onSelect={(checked)=> setDishWasher(checked)}/> 
        </div>
        <input className='hidden' name='whereDishWasher' value={whereDishWasher ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Y'a t'il un emplacement pour brancher un lave vaisselle ?</label>
          <Switch checked={whereDishWasher} onSelect={(checked)=> setWhereDishWasher(checked)}/> 
        </div>
        <input className='hidden' name='bed' value={bed ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Clic-clac ou vrai lit ?</label>
          <Switch checked={bed} onSelect={(checked)=> setBed(checked)}/> 
        </div>
        <input className='hidden' name='stateBed' value={stateBed ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Lit en bonne état ?</label>
          <Switch checked={stateBed} onSelect={(checked)=> setStateBed(checked)}/> 
        </div>
        <input className='hidden' name='stateAppartment' value={stateAppartment ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>L'appartement est-il en bon état général ?</label>
          <Switch checked={stateAppartment} onSelect={(checked)=> setStateAppartment(checked)}/> 
        </div>
        <input className='hidden' name='vitrage' value={vitrage ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Les fênetres sont simple ou double vitrage ?</label>
          <Switch checked={vitrage} onSelect={(checked)=> setVitrage(checked)}/> 
        </div>
        <input className='hidden' name='recentRadiator' value={recentRadiator ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Radiateurs récents ?</label>
          <Switch checked={recentRadiator} onSelect={(checked)=> setRecentRadiator(checked)}/> 
        </div>
        <input className='hidden' name='light' value={light ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Lumineux ?</label>
          <Switch checked={light} onSelect={(checked)=> setLight(checked)}/> 
        </div>
        <input className='hidden' name='visavis' value={visavis ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Vis à vis ?</label>
          <Switch checked={visavis} onSelect={(checked)=> setVisavis(checked)}/> 
        </div>
        <input className='hidden' name='noise' value={noise ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Beaucoup de bruit dehors ?</label>
          <Switch checked={noise} onSelect={(checked)=> setNoise(checked)}/> 
        </div>
        <input className='hidden' name='pc' value={pc ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Les parties communes sont en bon état ?</label>
          <Switch checked={pc} onSelect={(checked)=> setPC(checked)}/> 
        </div>
        <input className='hidden' name='road' value={road ? 'Oui' : 'Non'}/>
        <div className='flex justify-between items-center my-[20px]'>
          <label className='max-w-[80%]'>Sur cour ou sur rue ?</label>
          <Switch checked={road} onSelect={(checked)=> setRoad(checked)}/> 
        </div>
        <button className='bg-green-300 w-[100px] py-[10px] rounded-md my-[20px] ml-auto font-bold'>Envoyé</button>
      </form> 
    </main>
  );
}

export default App;
